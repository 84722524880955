export const pressData: PressDataType[] = [
  {
    title: '워프코퍼레이션, 레드불 레드파티서 헤더골 챌린지…‘숏폼 콘텐츠’ 제작',
    url: 'http://enter.etoday.co.kr/news/view/245983',
    description:
      '워프코퍼레이션(대표 함정수)이 레드불이 개최한 레드파티에 참가해 숏폼 콘텐츠를 제작하며 챌린지를 성료했다. 지난 8월4일부터 이틀간 양양 서피비치에서 개최된 ‘레드불 레드파티’에서 워프코퍼레이션 소속 크리에이터 더투탑은 다양한 이벤트를 진행했다. 특히 레드불 헤더골 챌린지는 지나가는 사람들에게 공을 던져주고 헤딩으로 아이스박스 원통에 골을 넣는 형식으로 진행되었으며, 챌린지에 성공한 사람들에게는 레드불 음료가 제공됐다.',
    date: '2023-08-16',
  },
  {
    title: '워프코퍼레이션, 방한 맨시티 선수와 숏폼 콘텐츠 협업',
    url: 'https://hobbyen-news.com/news/view/1065602950903871',
    description:
      '숏폼 콘텐츠 업체 워프코퍼레이션은 맨시티 선수들과 숏폼 콘텐츠 협업에 나섰다고 2일 밝혔다. 워프코퍼레이션 소속 크리에이터인 더투탑은 지난달 30일 한국을 방문 중인 맨시티 선수들과 다양한 콘텐츠를 제작했다. 세계적 축구스타 엘링 홀란드는 더투탑과 함께 맨시티 유니폼을 입고 이벤트에 참여한 팬들을 대상으로 친필 사인 유니폼을 나눠주기도 했다.',
    date: '2023-08-02',
  },
  {
    title: '숏폼 콘텐츠업체 워프코퍼레이션, 글로벌 크리에이터 콜라보 지속적 확대',
    url: 'http://www.issuenbiz.com/news/articleView.html?idxno=26120',
    description:
      '국내 숏폼 콘텐츠 업체 워프코퍼레이션은 협업하는 글로벌 크리에이터들과 함께 모여 숏폼 콘텐츠 협업 작업을 진행했다고 20일 밝혔다. 일본 인기 스포츠 크리에이터 레가테 타쿠야와 레가테 코헤이는 지난 16일부터 3박 4일 일정으로 한국을 방문해서 더투탑, 박한별초롱, 티아고킴 등과 함께 숏폼을 촬영했다. 워프코퍼레이션과 협업하는 글로벌 스포츠 크리에이터가 함께 모여 숏폼을 촬영한 것은 카타르 월드컵 이후 두 번째다. 당시 카타르에 모인 글로벌 크리에이터들은 다양한 숏폼을 촬영해 많은 호응을 얻은 바 있다.',
    date: '2023-06-20',
  },
  {
    title: '워프코퍼레이션, ‘스포츠+숏폼’ 3000만 이상 팔로워 확보',
    url: 'https://www.smarttoday.co.kr/news/articleView.html?idxno=29660',
    description:
      '워프코퍼레이션(대표 함정수)은 지난 3월 축구 분야에서 글로벌 탑 급 인플루언서들을 영입한지 1개월 만에 계약 크리에이터 수와 통합 팔로워 수가 두 배에 가까운 높은 성장세를 이뤘다고 4일 밝혔다. 최근 워프코퍼레이션과 계약한 스포츠 분야 숏폼 인플루언서들은 Michael Bolvin (910만 팔로워), Joris Sebregts (390만 팔로워), Omar (160만 팔로워), Dom Short (130만 팔로워) 등이다.',
    date: '2023-05-04',
  },
];

type PressDataType = Record<'title' | 'url' | 'description' | 'date', string>;
